/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'SaintGobain',
        clientId: 'vi-saint-gobain',
        buttonText: 'Identification Saint-Gobain',
      },
    ],
  ],

}
