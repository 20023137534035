const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({},
  config, {
    /**
     * common configuration here
     */
     siteId: '615f06aefbdbd26b99cdd8df',
     enableSplashScreen: 'wavy',
     showCardDescription: true,
     metadatas: {
       name: `imaGInons, le podcast`,
       colorPrimary: '#67b9b0',
       colorAccent: '#69605d',
       supportEmailAddress: 'support@imaginonslepodcast.fr',
     },
     authMode: [
      "local",
      {
        mode: "unique_password",
        defaultLogin: "digital+saintgobain@mediameeting.fr"
      }
    ],
     analytics: {
       matomo: '24',
     },
     useShell: [
       'bb-default',
       {
         mode: 'list'
       },
     ],
   },
   configEnvironments[process.env.BROCOLI_API_MODE],
   configLocal,
   envConfig
 )
 

module.exports = transformConfig(endConfig)
